import React from 'react';
import { TicketPrice, assertRelationIsObject } from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { getEntityId } from '@mapado/js-component';
import { NO_STOCK_CONTINGENT } from '@mapado/cart';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $secondaryGray } from '@mapado/makeup/variables';
import { getIcon } from '../../utils/seatStyle';
import BaseSeat, { SeatProps } from './BaseSeat';
import { useCurrentContingentContext } from '../CurrentContingentContext';

function SeatingPlanPurchaseSeat({
  color,
  isSelectable,
  isSelected,
  bookingStatus,
  seatEntity,
  getTicketPriceListBySeatGroup,
  isDesk,
  ...rest
}: Props) {
  const seatGroup = seatEntity?.seatGroup;

  assertRelationIsObject(seatGroup, 'seatGroup');

  const ticketPriceList = getTicketPriceListBySeatGroup(
    seatGroup['@id'],
    getEntityId(seatEntity.stockContingent)
  );

  const currentContingentId = useCurrentContingentContext();

  const hasTicketPrice = ticketPriceList && ticketPriceList.size > 0;

  const shouldBeSelectable = () => {
    if (currentContingentId && currentContingentId !== NO_STOCK_CONTINGENT) {
      return (
        hasTicketPrice &&
        isSelectable &&
        getEntityId(seatEntity.stockContingent) === currentContingentId
      );
    }

    if (currentContingentId === NO_STOCK_CONTINGENT) {
      return hasTicketPrice && isSelectable && !seatEntity.stockContingent;
    }

    return hasTicketPrice && isSelectable;
  };
  const seatIsSelectable = shouldBeSelectable();

  return (
    <BaseSeat<'AvailableSeat'>
      {...rest}
      isDesk={isDesk}
      seatEntity={seatEntity}
      isSelectable={seatIsSelectable}
      bookingStatus={bookingStatus}
      icon={isSelected ? 'seat-filled' : getIcon(bookingStatus, false)}
      fill={!seatIsSelectable && !isDesk ? $secondaryGray : color}
      isSelected={isSelected}
    />
  );
}

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
  getTicketPriceListBySeatGroup: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
};

SeatingPlanPurchaseSeat.displayName = 'SeatingPlanPurchaseSeat';

export default SeatingPlanPurchaseSeat;
